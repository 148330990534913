import React, { useState, useEffect, ChangeEvent } from "react";
import {
  PrimaryButton,
  SecondaryButton,
  Label,
  InputField,
  SelectDropdown,
  Link,
} from "@sentaraui/optimahealth_web";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { VALIDATION_LENGTH, groupSizeItems } from "utils/constants/Constants";
import { FormError } from "../../organisms/form/FormError";
import {
  AcceptNumeric,
  AlphaNumericSpace,
  EmailRegex,
  NameRegex,
  NumericHyphen,
} from "utils/constants/Regex";
import {
  NameChange,
  RemoveSpace,
  phoneCheck,
  pincodecheck,
} from "utils/helpers/HelperFn";
import { useBranding, useAuth } from "providers";

interface ContactUsFormProps {
  handleChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  dropdownValue: string;
  renewals: string;
  formattedPostalCode: string;
}

const ContactUsForm = ({
  handleChange,
  dropdownValue,
  renewals,
  formattedPostalCode,
}: ContactUsFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    reset,
    formState: { errors, isValid },
    getValues,
  } = useFormContext();
  const { user } = useAuth();
  const { brandConfig } = useBranding();
  const [email, setEmail] = useState<string>("");
  const [groupNumber, setGroupNumber] = useState("");
  const [additionalCarrier, setAdditionalCarrier] = useState("");
  const [checkboxMail, setCheckboxMail] = useState(false);
  const [checkboxPhone, setCheckboxPhone] = useState(false);
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [cityState, setCityState] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [renewalDate, setRenewalDate] = useState<string>("");
  const [lastKey, setLastKey] = useState("");

  const handleKeyDown = (event: any) => {
    setLastKey(event.key);

    if (event.target.value.length === 10 && AcceptNumeric.test(event.key)) {
      event.preventDefault();
    }
  };

  // useEffect(() => {
  //   console.log('RENEWALS: ', renewals);
  //   setRenewalDate(renewals);
  // }, [renewals]);
  const handleClearForm = () => {
    reset({
      groupSize: dropdownValue,
      // organization: "",

      phone: "",
      additionalCarriers: "",
      isHealthProductsInformation: "",
      isBehavioralHealthProductsInformation: "",
      isOtherInformation: "",
      question: "",
      IsPhoneOptIn: "",
      isMailOptIn: "",
      isEmailOptIn: "isEmailOptIn",
      fileAttachment: "",
    });
    setAdditionalCarrier("");
    setUserValues();
  };

  const setUserValues = () => {
    // setRenewalDate(renewals);
    setEmail(user?.emailAddresses?.[0]?.emailAddress ?? "");
    setGroupNumber(user?.groups?.map((group) => group.groupID).join(",") ?? "");
    setAddress1(user?.addresses?.[0]?.address1 ?? "");
    setAddress2(user?.addresses?.[0]?.address2 ?? "");
    setCityState(user?.addresses?.[0]?.city ?? "");
    setPostalCode(formattedPostalCode);
  };

  useEffect(() => {
    setUserValues();
  }, [user]);
  const isValidate = (isValid: boolean) => {
    if (isValid) {
      return !(
        getValues("isEmailOptIn") === "isEmailOptIn" ||
        getValues("isMailOptIn") === "isMailOptIn" ||
        getValues("IsPhoneOptIn") === "IsPhoneOptIn"
      )
        ? "primary_btn disabled_btn btn_block_mob"
        : `primary_btn btn_block_mob`;
    }
    return "primary_btn disabled_btn btn_block_mob";
  };

  const validateFields = (value: string, name: string): string | undefined => {
    if (getValues(name)?.length !== 0 && value?.length < 2) {
      return t("contactus.organizationMinLength", {
        value: value?.length,
      });
    }
  };

  const validateEmail = (value: string, name: string) => {
    if (getValues(name)?.length !== 0 && value?.length < 2) {
      return t("contactus.emailOneLength", { value: value?.length });
    } else if (getValues(name)?.length !== 0 && value?.length < 5) {
      return t("contactus.emailMinLength", { value: value?.length });
    } else if (getValues(name)?.length !== 0 && !EmailRegex.test(value)) {
      return t("contactus.validationEmail");
    }
  };
  const validateContact = () => {
    if (
      getValues("isEmailOptIn") !== "isEmailOptIn" &&
      getValues("isMailOptIn") !== "isMailOptIn" &&
      getValues("IsPhoneOptIn") !== "IsPhoneOptIn"
    ) {
      return t("contactus.atLeastOneContact");
    }
  };

  const validateGroupNumber = (value: string, name: string) => {
    if (getValues(name)?.length !== 0 && value?.length !== 0){
      const groupNum = value?.split(',');
          for (const item of groupNum) {
            if (item?.length < 4) {
              return t("contactus.groupNumberMinLength", { value: item?.length });
            }
          }
     
    } 
  };

  register("organization", {
    validate: (value: string) => validateFields(value, "organization"),
  });
  register("address1", {
    validate: (value: string) => validateAddress(value),
  });
  register("phone", {
    validate: (value: string) => validatePhone(value),
  });

  register("postalCode", {
    validate: (value: string) => validatePostalCode(value),
  });

  register("cityState", {
    validate: (value: string) => validateCityState(value),
  });

  const validatePostalCode = (value: string) => {
    if (getValues("isMailOptIn") === "isMailOptIn") {
      if (!value) {
        return t("contactus.validateZipCode");
      }
    }

    if (value && value?.length !== 0 && value?.length === 1) {
      return t("contactus.emailOneLength", { value: value?.length });
    } else if (value && value?.length !== 0 && value?.length < 5) {
      return t("contactus.emailMinLength", {
        value: value?.length,
      });
    }
  };

  const validateCityState = (value: string) => {
    if (getValues("isMailOptIn") === "isMailOptIn") {
      if (!value) {
        return t("contactus.validateState");
      }
    }
    if (value && value?.length !== 0 && value?.length < 2) {
      return t("contactus.organizationMinLength", {
        value: value?.length,
      });
    }
  };

  const validateAddress = (value: string) => {
    if (getValues("isMailOptIn") === "isMailOptIn") {
      if (!value) {
        return t("contactus.validateAddress");
      }
    }
    if (value && value?.length !== 0 && value?.length < 2) {
      return t("contactus.organizationMinLength", {
        value: value?.length,
      });
    }
  };

  const validatePhone = (value: string) => {
    if (getValues("IsPhoneOptIn") === "IsPhoneOptIn") {
      if (!value) {
        return t("contactus.requirePhone");
      }
    }
    const phoneNumber = value?.replace(/-/g, "");

    if (phoneNumber && phoneNumber?.length !== 0 && phoneNumber?.length < 10) {
      return t("contactus.validatePhone");
    }
  };

  register("contact", { validate: validateContact });

  const validClass = (name: string) => {
    if (errors[name]) {
      return "form-input error-input-bdr";
    }
    return "form-input";
  };

  const errorClass = (name: string) => {
    if (errors[name]) {
      return "";
    }
    return "d-none";
  };
  const required = (
    <strong className="text-danger ps-1" aria-label={t("required")}>
      *
    </strong>
  );

  const handleMailOption = () => {
    setCheckboxMail(!checkboxMail);
  };

  const handlePhoneOption = () => {
    setCheckboxPhone(!checkboxPhone);
  };


  // Function to handle formatting of the input of dates, including validation
  const handleDateInput = (value: string) => {
    if (!value) return ''; // Return empty if no input
    
    // Clean the input by removing invalid characters at the start and normalizing slashes
    const cleanedInput = value.trim().replace(/^[^0-9]/, ''); // Remove invalid first character
    const normalizedInput = cleanedInput.replace(/\/{2,}/g, '/'); // Remove double slashes

    // Split by either commas or spaces (handle both separators)
    const parts = normalizedInput.split(/[, ]+/).map(part => formatDateInput(part.trim()));

    // Join formatted parts with a comma and space
    return parts.join(', ').trim(); // Trim to remove extra spaces at the end
  };

  // Function to format each individual date as MM/DD/YYYY
  const formatDateInput = (value: string) => {
    if (!value) return ''; // Return empty if no input

    // Remove everything except numbers and slashes
    let cleanedValue = value.replace(/[^0-9/]/g, "");

    // Limit to 8 digits for MMDDYYYY
    const numericValue = cleanedValue.replace(/\//g, '').slice(0, 8);

    let formattedDate = '';
    if (numericValue.length <= 2) {
      formattedDate = numericValue; // MM
    } else if (numericValue.length <= 4) {
      formattedDate = `${numericValue.slice(0, 2)}/${numericValue.slice(2)}`; // MM/DD
    } else {
      formattedDate = `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}/${numericValue.slice(4)}`; // MM/DD/YYYY
    }

    return formattedDate;
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsFirstName"
                htmlFor="inputFirstName"
              >
                {t("contactus.labelFirstName")}
                {required}
              </Label>
            </div>
            <InputField
              className="form-input"
              type="text"
              value={user?.person?.firstName}
              dataTestId="inputContactUsFirstName"
              {...register("firstName")}
              id="inputFirstName"
              aria-describedby="inputFirstName_error"
              disabled={true}
            />
            <div
              id="inputFirstName_error"
              aria-live="polite"
              aria-atomic="true"
            >
              <FormError name="firstName" />
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsLastName"
                htmlFor="inputLastName"
              >
                {t("contactus.labelLastName")}
                {required}
              </Label>
            </div>
            <div>
              <InputField
                className="form-input"
                value={user?.person?.lastName}
                type="text"
                id="inputLastName"
                {...register("lastName")}
                dataTestId="inputContactUsLastName"
                aria-describedby="inputLastName_error"
                disabled={true}
              />
            </div>
            <div id="inputLastName_error" aria-live="polite" aria-atomic="true">
              <FormError name="lastName" />
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsOrgName"
                htmlFor="inputOrgName"
              >
                {t("contactus.labelOrganization")}
                {required}
              </Label>
            </div>
            <InputField
              pattern={AlphaNumericSpace}
              maxLength={VALIDATION_LENGTH.ORGANIZATION_MAX_LENGTH}
              placeholder={t("contactus.plcOrganization")}
              className={validClass("organization")}
              type="text"
              id="inputOrgName"
              {...register("organization")}
              value={user?.groups?.[0]?.groupName}
              dataTestId="inputContactUsOrgName"
              aria-describedby="organization_error"
              disabled={true}
            />
            <div className={errorClass("organization")}>
              <div
                id="organization_error"
                aria-live="polite"
                aria-atomic="true"
              >
                <FormError name="organization" />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsGrpSize"
                htmlFor="inputGroupSize"
              >
                {t("contactus.labelGroupSize")}
              </Label>
            </div>
            <div>
              <SelectDropdown
                className={
                  errors.groupId?.message
                    ? " form-select error-input-bdr"
                    : "form-select"
                }
                {...register("groupSize")}
                dataTestId="optContactUsGrpSize"
                defaultValue=""
                id="inputGroupSize"
                name="groupSize"
                onChange={handleChange}
              >
                <option value={dropdownValue} disabled>
                  {t("contactus.chooseGroupSize")}
                </option>

                {groupSizeItems.map((items: any, index: number) => (
                  <option value={items.value} key={index}>
                    {items.value}
                  </option>
                ))}
              </SelectDropdown>
            </div>
            <div className="granite-gray-color pt-1">
              <small>{t("contactus.groupSizeNotes")}</small>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsEmail"
                htmlFor="inputEmail"
              >
                {t("contactus.labelEmail")}
                {required}
              </Label>
            </div>
            <div>
              <InputField
                className={validClass("email")}
                type="text"
                value={email}
                maxLength={VALIDATION_LENGTH.EMAIL_MAX_LENGTH}
                id="inputEmail"
                placeholder={t("contactus.emailPlcHolder")}
                dataTestId="inputContactUsEmail"
                {...register("email", {
                  required: t("contactus.requiredEmail"),
                  validate: (value: string) => validateEmail(value, "email"),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    RemoveSpace(e);
                    setEmail(e.target.value);
                  },
                })}
                aria-describedby="contactus_email_error"
              />
            </div>
            <div className={errorClass("email")}>
              <div
                id="contactus_email_error"
                aria-live="polite"
                aria-atomic="true"
              >
                <FormError name="email" />
              </div>
            </div>
            <div className="granite-gray-color pt-1">
              <small>
                {t("contactus.privacyPolicyContent1")}
                <Link
                  to={brandConfig.link.privacyPolicy}
                  target="_blank"
                  className="redhat_regular px-1 text-decoration-underline"
                  data-testid="lnkPrivacyPolicy"
                >
                  {t("contactus.privacyPolicyLink")}
                </Link>
                {t("contactus.privacyPolicyContent2")}
              </small>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsAddress"
                htmlFor="address1"
              >
                {t("contactus.labelAddress")}
                {getValues("isMailOptIn") === "isMailOptIn" && (
                  <span>{required}</span>
                )}
              </Label>
            </div>
            <div>
              <InputField
                className={validClass("address1")}
                type="text"
                id="address1"
                value={address1}
                maxLength={VALIDATION_LENGTH.ADDRESS_MAX_LENGTH}
                placeholder={t("contactus.addressPlcHolder")}
                {...register("address1", {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    RemoveSpace(e);
                    setAddress1(e.target.value);
                  },
                })}
                dataTestId="inputContactUsAddressLine1"
                aria-describedby="addressLine1_error"
              />
            </div>
            <div className={errorClass("address1")}>
              <div
                id="addressLine1_error"
                aria-live="polite"
                aria-atomic="true"
              >
                <FormError name="address1" />
              </div>
            </div>
          </div>

          <div className="mb-4">
            <InputField
              className="form-input"
              type="text"
              maxLength={VALIDATION_LENGTH.ADDRESS_MAX_LENGTH}
              id="inputAddressLine2"
              placeholder={t("contactus.addressPlcHolder2")}
              value={address2}
              {...register("address2", {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  RemoveSpace(e);
                  setAddress2(e.target.value);
                },
              })}
              dataTestId="inputContactUsAddressLine2"
            />
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsCityOrState"
                htmlFor="inputCityAndState"
              >
                {t("contactus.labelCityOrState")}
                {getValues("isMailOptIn") === "isMailOptIn" && (
                  <span>{required}</span>
                )}
              </Label>
            </div>
            <div>
              <InputField
                className={validClass("cityState")}
                pattern={NameRegex}
                type="text"
                maxLength={VALIDATION_LENGTH.CITY_MAX_LENGTH}
                placeholder={t("contactus.cityPlcHolder")}
                id="inputCityAndState"
                value={cityState}
                {...register("cityState", {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    NameChange(e);
                    setCityState(e.target.value);
                  },
                })}
                dataTestId="inputContactUsCityOrState"
                aria-describedby="cityOrState_error"
              />
              <div className={errorClass("cityState")}>
                <div
                  id="cityOrState_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="cityState" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsZipCode"
                htmlFor="inputZipOrPostalCode"
              >
                {t("contactus.labelZipOrPostalCode")}
                {getValues("isMailOptIn") && <span>{required}</span>}
              </Label>
            </div>
            <div>
              <InputField
                pattern={NumericHyphen}
                placeholder={t("contactus.plcPostalCode")}
                className={validClass("postalCode")}
                value={postalCode}
                type="tel"
                maxLength={VALIDATION_LENGTH.PHONENUMBER_MAX_LENGTH}
                id="inputZipOrPostalCode"
                onKeyDown={handleKeyDown}
                {...register("postalCode", {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    pincodecheck(e, lastKey);
                    setPostalCode(e.target.value);
                  },
                })}
                dataTestId="inputContactUsZipCode"
                aria-describedby="zipCode_error"
              />
              <div className={errorClass("postalCode")}>
                <div id="zipCode_error" aria-live="polite" aria-atomic="true">
                  <FormError name="postalCode" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsPhoneNum"
                htmlFor="inputDaytimePhone"
              >
                {t("contactus.labelDaytimePhone")}
                {getValues("IsPhoneOptIn") === "IsPhoneOptIn" && (
                  <span>{required}</span>
                )}
              </Label>
            </div>
            <div>
              <InputField
                placeholder={t("contactus.plcHolderPhone")}
                type="tel"
                maxLength={10}
                className={validClass("phone")}
                id="inputDaytimePhone"
                {...register("phone", {
                  validate: validatePhone,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    phoneCheck(e);
                  },
                })}
                dataTestId="inputContactUsPhoneNum"
                forceNumber={true}
                aria-describedby="phone_error"
              />
              <div className={errorClass("phone")}>
                <div id="phone_error" aria-live="polite" aria-atomic="true">
                  <FormError name="phone" />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsRenewalDate"
                htmlFor="inputRenewalDate"
                children={t("contactus.labelRenewalDate")}
              />
            </div>
            <div>
              <InputField
                placeholder={t("contactus.plcRenewalDate")}
                type="text"
                id="inputRenewalDate"
                value={renewalDate}
                maxLength={VALIDATION_LENGTH.RENEWAL_DATE_MAX_LENGTH}
                {...register("renewalDate", {
                  validate: (value: string) => validateGroupNumber(value, "renewalDate"),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    const rawValue = e.target.value;
                    // Clean the input to only allow specified characters
                    const cleanedInput = rawValue.replace(/[^0-9/,\s]/g, ""); // Allow only numbers, slashes, commas, and spaces
                    setRenewalDate(cleanedInput);
                  },
                  onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                    // Format input on blur to MM/DD/YYYY
                    const formattedValue = handleDateInput(e.target.value);
                    setRenewalDate(formattedValue);
                  },
                })}
                dataTestId="inputContactUsRenewalDate"
              />
            </div>
          </div>

          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsHealthPlanGroupNum"
                htmlFor="inputGroupNumber"
                children={t("contactus.labelGroupNumber")}
              />
              {required}
            </div>
            <div>
              <InputField
                placeholder={t("contactus.plcGroupNumber")}
                className={validClass("groupNumbers")}
                type="text"
                id="inputGroupNumber"
                maxLength={VALIDATION_LENGTH.RENEWAL_DATE_MAX_LENGTH}
                {...register("groupNumber", {
                  validate: (value: string) =>
                    validateGroupNumber(value, "groupNumber"),
                  required: t("contactus.requireGroupNumber"),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    RemoveSpace(e);
                    setGroupNumber(e.target.value);
                  },
                })}
                dataTestId="inputContactUsHealthPlanGroupNum"
                aria-describedby="groupNumber_error"
              />
            </div>
            <div>
              <div
                id="select_group_error"
                aria-live="polite"
                aria-atomic="true"
              >
                <FormError name="groupNumber" />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsAdditionalCarriers"
                htmlFor="inputAdditionalCarriers"
                children={t("contactus.labelAdditionalCarriers")}
              />
            </div>
            <div>
              <InputField
                placeholder={t("contactus.plcAdditionalCarriers")}
                className={validClass("additionalCarriers")}
                type="text"
                id="inputAdditionalCarriers"
                value={additionalCarrier}
                maxLength={VALIDATION_LENGTH.RENEWAL_DATE_MAX_LENGTH}
                {...register("additionalCarriers", {
                  validate: (value: string) =>
                    validateGroupNumber(value, "additionalCarriers"),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    RemoveSpace(e);
                    setAdditionalCarrier(e.target.value);
                  },
                })}
                dataTestId="InputContactUsAdditionalCarriers"
                aria-describedby="additionalCarriers_error"
              />
              <div>
                <div
                  id="additionalCarriers_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="additionalCarriers" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsIWouldLikeInfoOn"
                htmlFor="inputWouldlikeInformationOn"
                children={t("contactus.labelWouldlikeInformationOn")}
              />
            </div>

            <div className="pt-1">
              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"isHealthProductsInformation"}
                  value={"isHealthProductsInformation"}
                  dataTestId={"chkHealthProducts"}
                  {...register("isHealthProductsInformation")}
                />
                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`isHealthProductsInformation`}
                >
                  {t("contactus.healthProducts")}
                </label>
              </div>

              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"isBehavioralHealthProductsInformation"}
                  value={"isBehavioralHealthProductsInformation"}
                  dataTestId={"chkBehavioralHealthProducts"}
                  {...register("isBehavioralHealthProductsInformation")}
                />
                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`isBehavioralHealthProductsInformation`}
                >
                  {t("contactus.behavioralHealthProducts")}
                </label>
              </div>

              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"isOtherInformation"}
                  value={"isOtherInformation"}
                  dataTestId={"chkOthers"}
                  {...register("isOtherInformation")}
                />
                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`isOtherInformation`}
                >
                  {t("contactus.other")}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsQuestion"
                htmlFor="inputQuestion"
              >
                {t("contactus.labelQuestion")}
                {required}
              </Label>
            </div>
            <div>
              <textarea
                className={validClass("question")}
                rows={9}
                maxLength={VALIDATION_LENGTH.ORGANIZATION_MAX_LENGTH}
                id="inputQuestion"
                placeholder={t("contactus.questionPlcHolder")}
                {...register("question", {
                  required: t("contactus.requiredQuestion"),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    RemoveSpace(e);
                  },
                })}
                data-testid="inputContactUsQuestion"
                aria-describedby="question_error"
              />
              <div className={errorClass("question")}>
                <div id="question_error" aria-live="polite" aria-atomic="true">
                  <FormError name="question" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsHowWouldLikeToContact"
                htmlFor="inputHowWouldLikeToContact"
              >
                {t("contactus.labelHowWouldLikeToContact")}
                {required}
              </Label>
            </div>
            <div>
              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"IsPhoneOptIn"}
                  value={"IsPhoneOptIn"}
                  dataTestId={"chkContactUsContactViaPhone"}
                  {...register("IsPhoneOptIn", {
                    onChange: handlePhoneOption,
                  })}
                />
                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`IsPhoneOptIn`}
                >
                  {t("contactus.phone")}
                </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"isMailOptIn"}
                  value={"isMailOptIn"}
                  dataTestId={"chkContactUsContactViaMail"}
                  {...register("isMailOptIn", {
                    onChange: handleMailOption,
                  })}
                />

                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`isMailOptIn`}
                >
                  {t("contactus.mail")}
                </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"isEmailOptIn"}
                  value={"isEmailOptIn"}
                  dataTestId={"chkContactUsContactViaEmail"}
                  {...register("isEmailOptIn")}
                  aria-describedby="emailOptIn_error"
                />
                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`isEmailOptIn`}
                >
                  {t("contactus.email")}
                </label>
              </div>
            </div>
            <div className={errorClass("contact")}>
              <div id="emailOptIn_error" aria-live="polite" aria-atomic="true">
                <FormError name="contact" />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div>
              <Label
                className="pb-1"
                dataTestId="lblContactUsIncludeAttachment"
                htmlFor="inputIncludeAttachment"
                children={t("contactus.labelIncludeAttachment")}
              />
              <span className="visually-hidden" id="contactus_file_upload">
                Click to upload your file
              </span>
            </div>
            <div>
              <input
                data-testid="inputContactUsIncludeAttachement"
                placeholder="Upload"
                className="text-decoration-none border-0"
                type="file"
                id="inputIncludeAttachment"
                aria-describedby="contactus_file_upload"
                {...register("fileAttachment")}
              />
            </div>
          </div>
        </div>
      </div>
      <div>{t("contactus.notes")}</div>
      <hr className="my-4" />
      <div className="d-flex flex-lg-row flex-md-row flex-column gap-4 gap-lg-3 gap-md-3 mb-4">
        <PrimaryButton
          data-testid="btnContactUsSubmit"
          id="primary"
          type="submit"
          name="submit"
          className={isValidate(isValid)}
          aria-label={
            isValid ? t("contactus.submit") : t("contactus.submitDisabled")
          }
        >
          {t("contactus.submit")}
        </PrimaryButton>
        <SecondaryButton
          data-testid="btnContactUsClear"
          id="primary"
          type="button"
          className={"secondary_btn btn_block_mob"}
          onClick={handleClearForm}
        >
          {t("button.label.clear")}
        </SecondaryButton>
      </div>
    </div>
  );
};

export default ContactUsForm;
